import React from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";

import Blog from "../../assets/json/blog.json";

const articleData = {
    id: 2,
    title: "Cosa aspettarsi dal Metaverso",
    desc:
        "Nel 1992 Neal Stephenson coniò per la prima volta il termine Metaverso nel suo romanzo cyberpunk “Snow Crash”. Dopo 30 anni, numerosi tentativi ed evoluzioni, un nuovo mondo virtuale e immersivo è qui a cambiare i nostri equilibri economici e sociali.",
    img: "/blog-image/metaverso1.webp",
    page: "blog/metaverso",
    data: "20 Lug 2022",
    author: "Staff",
};

const BlogDetails = () => (
    <Layout>
        <SEO
            title={articleData.title}
            meta={[
                {
                    name: `description`,
                    content:
                        "Nel 1992 Neal Stephenson coniò per la prima volta il termine Metaverso nel suo romanzo cyberpunk “Snow Crash”.",
                },
                {
                    property: `og:title`,
                    content: articleData.title,
                },
            ]}
        />

        <Navbar />

        <PageBanner pageTitle={articleData.title} />

        <div className="blog-details-area ptb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="blog-details-desc">
                            <div className="article-image">
                                <img
                                    src={
                                        require("../../assets/images" +
                                            articleData.img).default
                                    }
                                    alt="image"
                                />
                            </div>

                            <div className="article-content">
                                <div className="entry-meta">
                                    <ul>
                                        <li>
                                            <Icon.Clock /> {articleData.data}
                                        </li>
                                        <li>
                                            <Icon.User />{" "}
                                            <Link to="#">
                                                {articleData.author}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <blockquote style={{ textAlign: "right" }}>
                                    <p>
                                        <i>
                                            “Well, all information looks like
                                            noise until you break the code”
                                        </i>
                                    </p>
                                    <h6>-Neal Stephenson, Snow Crash</h6>
                                </blockquote>

                                <p>
                                    Nel 1992 Neal Stephenson coniò per la prima
                                    volta il termine Metaverso nel suo romanzo
                                    cyberpunk “Snow Crash”. Dopo 30 anni,
                                    numerosi tentativi ed evoluzioni, un nuovo
                                    mondo virtuale e immersivo è qui a cambiare
                                    i nostri equilibri economici e sociali.
                                    <br />
                                    Ma cosa possiamo (e dobbiamo) aspettarci da
                                    questo fenomeno?
                                </p>
                                <p>
                                    Dal punto di vista professionale, il nuovo
                                    strumento del Metaverso porterà con sé la
                                    nascita di nuovi lavori. La realtà virtuale
                                    e la realtà aumentata garantiranno ambienti
                                    realistici dove i nostri avatar discuteranno
                                    di lavoro e genereranno nuovi contesti dove
                                    fare e creare business. Collaborare con
                                    persone situate in luoghi lontani (già
                                    semplificato con gli attuali strumenti di
                                    videoconferenza 2D) sarà più facile e
                                    realistico grazie a nuovi strumenti come
                                    l’Oculus. Il Metaverso favorirebbe anche la
                                    formazione, facilitando l’interazione tra
                                    colleghi e subordinati all’interno delle
                                    aziende che abbracciano il lavoro da remoto
                                    - elemento apprezzato ma con dei limiti
                                    segnalati dai lavoratori stessi per quanto
                                    riguarda il formare e l’essere formati.
                                    <br />
                                    Le grandi istituzioni informatiche, che
                                    decidono cosa è strategico e cosa no, negli
                                    anni hanno dato un peso crescente al
                                    Metaverso. Guardiamo qualche dato: a partire
                                    dal 2021 Meta Platform assume migliaia di
                                    persone in Europa per lavorare allo sviluppo
                                    del Metaverso. Facebook, nello stesso anno,
                                    cambia nome in Meta per allacciare ancor di
                                    più la propria immagine al Metaverso. Altre
                                    aziende, come Microsoft e Apple destinano
                                    sempre più risorse ad investimenti e
                                    ricerche sul Metaverso. Senza tralasciare,
                                    dal lato prettamente finanziario, la
                                    rilevanza crescente delle criptovalute nei
                                    mercati.
                                    <br />
                                    Una vera e propria rivoluzione, insomma. Non
                                    molto diversa da quella suscitata dalla
                                    nascita di Internet anni fa.
                                    <br />
                                    Come si comporteranno le Imprese italiane in
                                    tutto ciò? Agiranno tempestivamente o si
                                    faranno trovare impreparate come sta
                                    avvenendo con la transizione digitale verso
                                    l’industria 4.0?
                                </p>
                                <br />
                                <p>
                                    Se in ambito professionale il Metaverso
                                    sembra essere una fonte di nuove carriere e
                                    veicolo di una formazione più accessibile, è
                                    d’obbligo discutere sull’aspetto più umano
                                    del lavoratore: il “fattore individuo”. Non
                                    possiamo vivere senza la socialità.
                                    L’importanza dell’altro, dello svago e dello
                                    svago con gli altri sono elementi
                                    fondamentali della vita. Il Metaverso oltre
                                    a presentare un’evoluzione del lavoro,
                                    sottolinea un focus verso la socializzazione
                                    nel nuovo mondo virtuale.
                                    <br />
                                    Se la creazione e lo sviluppo dell’ampio
                                    progetto del Metaverso sono il risultato di
                                    studi scientifici, matematica e codici, gli
                                    individui che lo andranno a popolare no.
                                    <br />
                                    Con lo scoppio della pandemia da Covid-19
                                    molte delle cose che davamo per scontate
                                    sono venute meno - le nostre abitudini
                                    completamente rivoluzionate. Ci portiamo
                                    dietro gli strascichi di mesi e mesi di
                                    privazioni e limitazioni sociali, ci risulta
                                    ancora difficile abbracciare e stringere la
                                    mano di qualcuno senza calcolare dei rischi.
                                    Vogliamo viaggiare di più perché per troppo
                                    tempo non abbiamo potuto. Le nostre giornate
                                    sono state accompagnate a lungo da angoscia
                                    e poca libertà. Le emozioni che scaturiscono
                                    dallo stare a contatto e divertirci con le
                                    persone a noi care hanno acquisito un valore
                                    molto più forte rispetto a prima della
                                    pandemia.
                                </p>

                                <img
                                    src={
                                        "https://images.everyeye.it/img-articoli/realta-virtuale-giochi-vr-attesi-2021-v8-51595.jpg"
                                    }
                                    style={{ marginBottom: 40, marginTop: 30 }}
                                    alt="Multiverso"
                                />
                                <br />

                                <p>
                                    Non perché prima non fossero importanti, ma
                                    perché ora tutti noi sappiamo cosa vuol dire
                                    stare senza.
                                    <br />
                                    In un mondo che si affaccia ad una realtà di
                                    lavoro e di vita virtuale (perché nel
                                    metatarso puoi seguire concerti e comprare
                                    le cose) dove hai modo di incontrarti e
                                    relazionarti con gli altri - a farlo sarà il
                                    tuo avatar e non la tua vera persona - che
                                    ruolo avrà e come inciderà il fattore
                                    emozionale?
                                    <br />
                                    L’individuo, come lavoratore e essere umano,
                                    necessita di pause (di diritto e di buon
                                    senso) dal proprio computer e dalla propria
                                    presenza online. Come risponderà il
                                    Metaverso alle esigenze emozionali delle
                                    persone? E, viceversa, come risponderanno le
                                    persone alle esperienze virtuali del
                                    Metaverso? Tutto fa presumere che non
                                    dovremo aspettare ancora a lungo per
                                    rispondere a queste domande.
                                </p>

                                <p>
                                    Seppur la realizzazione del Metaverso sia
                                    ancora in corso, alcune caratteristiche e
                                    funzionalità iniziano a essere disponibili:
                                    è già possibile giocare, lavorare e
                                    concludere trattative; gli utenti possono
                                    mettere a disposizione di altri dei servizi;
                                    è possibile utilizzare degli strumenti di
                                    realtà aumentata per rendere più interattiva
                                    e reale la propria permanenza ed è già
                                    permesso utilizzare valute, cripto e reali,
                                    per svolgere attività.
                                </p>
                                <p>
                                    Le innovazioni tecnologiche esistono da
                                    sempre - fortunatamente. La qualità della
                                    vita è migliorata grazie al progresso e
                                    all’evoluzione. Qualcosa che succede
                                    ciclicamente e che modifica per sempre il
                                    nostro modo di vivere, basti pensare al già
                                    citato avvento di Internet. I cambiamenti
                                    sono inevitabili, obbligatorie sono le
                                    calibrature: tutti devono poter beneficiare
                                    dei lati positivi delle nuove tecnologie e
                                    tutti devono ricevere gli strumenti per
                                    proteggersi dalle stesse.Un mondo high tech
                                    che mette al centro l’individuo. Questo è
                                    quello che ci auguriamo e quello per cui ci
                                    impegniamo ogni giorno. <br />
                                </p>
                                <h5>#web3 #blockchain</h5>
                                <br />
                                <Link to="/contact" className="btn btn-primary">
                                    Contattaci
                                </Link>
                            </div>

                            <div
                                style={{ marginTop: 30 }}
                                className="startp-post-navigation"
                            >
                                {Blog.articles
                                    .filter((a) => a.id != articleData.id)
                                    .slice(0, 2)
                                    .map((article) => {
                                        return (
                                            <div className="prev-link-wrapper">
                                                <div className="info-prev-link-wrapper">
                                                    <Link
                                                        to={"/" + article.page}
                                                    >
                                                        <span className="image-prev">
                                                            <img
                                                                src={
                                                                    require("../../assets/images" +
                                                                        article.img)
                                                                        .default
                                                                }
                                                                alt="image"
                                                            />
                                                            <span className="post-nav-title">
                                                                VAI
                                                            </span>
                                                        </span>

                                                        <span className="prev-link-info-wrapper">
                                                            <span className="prev-title">
                                                                {article.title}
                                                            </span>
                                                            <span className="meta-wrapper">
                                                                <span className="date-post">
                                                                    {
                                                                        article.data
                                                                    }
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <BlogSidebar id={articleData.id} />
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </Layout>
);

export default BlogDetails;
